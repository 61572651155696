<script setup lang="ts">
defineProps({
    color: {
        type: String,
        default: "#000000"
    }
});

const randomId = computed(() => {
    return Math.random().toString(36).substr(2, 9);
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="50"
        height="50"
        viewBox="0 0 50 50"
        fill="none"
    >
        <path
            d="M43 25.5C43 35.165 35.165 43 25.5 43C15.835 43 8 35.165 8 25.5C8 15.835 15.835 8 25.5 8C35.165 8 43 15.835 43 25.5Z"
            :fill="'url(#paint0_angular_598_7773' + randomId + ')'"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25 45.125C36.1147 45.125 45.125 36.1147 45.125 25C45.125 13.8853 36.1147 4.875 25 4.875C13.8853 4.875 4.875 13.8853 4.875 25C4.875 36.1147 13.8853 45.125 25 45.125ZM25 46C36.598 46 46 36.598 46 25C46 13.402 36.598 4 25 4C13.402 4 4 13.402 4 25C4 36.598 13.402 46 25 46Z"
            :fill="'url(#paint1_angular_598_7773' + randomId + ')'"
        />
        <g :filter="'url(#filter0_f_598_7773' + randomId + ')'">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M25 45.125C36.1147 45.125 45.125 36.1147 45.125 25C45.125 13.8853 36.1147 4.875 25 4.875C13.8853 4.875 4.875 13.8853 4.875 25C4.875 36.1147 13.8853 45.125 25 45.125ZM25 46C36.598 46 46 36.598 46 25C46 13.402 36.598 4 25 4C13.402 4 4 13.402 4 25C4 36.598 13.402 46 25 46Z"
                :fill="'url(#paint2_angular_598_7773 ' + randomId + ')'"
            />
        </g>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.5 8.82927C21.0908 8.82927 16.8621 10.4893 13.7444 13.4441C10.6266 16.399 8.875 20.4066 8.875 24.5854C8.875 24.8144 8.67912 25 8.4375 25C8.19588 25 8 24.8144 8 24.5854C8 20.1867 9.84375 15.9681 13.1256 12.8577C16.4075 9.74738 20.8587 8 25.5 8C30.1413 8 34.5925 9.74738 37.8744 12.8577C41.1563 15.9681 43 20.1867 43 24.5854C43 24.8144 42.8041 25 42.5625 25C42.3209 25 42.125 24.8144 42.125 24.5854C42.125 20.4066 40.3734 16.399 37.2556 13.4441C34.1379 10.4893 29.9092 8.82927 25.5 8.82927Z"
            :fill="'url(#paint3_linear_598_7773' + randomId + ')'"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M25.5 42.106C23.3172 42.106 21.1558 41.6666 19.1392 40.813C17.1225 39.9594 15.2902 38.7082 13.7467 37.1309C13.5759 36.9564 13.2989 36.9564 13.1281 37.1309C12.9573 37.3055 12.9573 37.5885 13.1281 37.7631C14.7528 39.4234 16.6816 40.7404 18.8044 41.639C20.9272 42.5375 23.2023 43 25.5 43C27.7977 43 30.0728 42.5375 32.1956 41.639C34.3184 40.7404 36.2472 39.4234 37.8719 37.7631C38.0427 37.5885 38.0427 37.3055 37.8719 37.1309C37.7011 36.9564 37.4241 36.9564 37.2533 37.1309C35.7098 38.7082 33.8775 39.9594 31.8608 40.813C29.8442 41.6666 27.6828 42.106 25.5 42.106Z"
            :fill="'url(#paint4_linear_598_7773' + randomId + ')'"
        />
        <defs>
            <filter
                :id="'filter0_f_598_7773' + randomId"
                x="0.7"
                y="0.7"
                width="48.6"
                height="48.6"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                />
                <feGaussianBlur
                    stdDeviation="1.65"
                    result="effect1_foregroundBlur_598_7773"
                />
            </filter>
            <radialGradient
                :id="'paint0_angular_598_7773' + randomId"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(25.5 25.5) rotate(90) scale(17.5)"
            >
                <stop :stop-color="color" stop-opacity="0.5" />
                <stop
                    offset="0.251736"
                    :stop-color="color"
                    stop-opacity="0.11"
                />
                <stop
                    offset="0.496527"
                    :stop-color="color"
                    stop-opacity="0.5"
                />
                <stop
                    offset="0.756944"
                    :stop-color="color"
                    stop-opacity="0.11"
                />
                <stop offset="0.9999" :stop-color="color" stop-opacity="0.5" />
            </radialGradient>
            <radialGradient
                :id="'paint1_angular_598_7773' + randomId"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(25 25) rotate(90) scale(21)"
            >
                <stop :stop-color="color" />
                <stop
                    offset="0.0989583"
                    :stop-color="color"
                    stop-opacity="0.25"
                />
                <stop
                    offset="0.244792"
                    :stop-color="color"
                    stop-opacity="0.84"
                />
                <stop
                    offset="0.380208"
                    :stop-color="color"
                    stop-opacity="0.3"
                />
                <stop
                    offset="0.458333"
                    :stop-color="color"
                    stop-opacity="0.87"
                />
                <stop
                    offset="0.557292"
                    :stop-color="color"
                    stop-opacity="0.25"
                />
                <stop
                    offset="0.645833"
                    :stop-color="color"
                    stop-opacity="0.19"
                />
                <stop
                    offset="0.723958"
                    :stop-color="color"
                    stop-opacity="0.93"
                />
                <stop
                    offset="0.854167"
                    :stop-color="color"
                    stop-opacity="0.33"
                />
                <stop
                    offset="0.953125"
                    :stop-color="color"
                    stop-opacity="0.623529"
                />
                <stop offset="1" :stop-color="color" />
            </radialGradient>
            <radialGradient
                :id="'paint2_angular_598_7773' + randomId"
                cx="0"
                cy="0"
                r="1"
                gradientUnits="userSpaceOnUse"
                gradientTransform="translate(25 25) rotate(90) scale(21)"
            >
                <stop :stop-color="color" />
                <stop
                    offset="0.0989583"
                    :stop-color="color"
                    stop-opacity="0.25"
                />
                <stop
                    offset="0.244792"
                    :stop-color="color"
                    stop-opacity="0.84"
                />
                <stop
                    offset="0.380208"
                    :stop-color="color"
                    stop-opacity="0.3"
                />
                <stop
                    offset="0.458333"
                    :stop-color="color"
                    stop-opacity="0.87"
                />
                <stop
                    offset="0.557292"
                    :stop-color="color"
                    stop-opacity="0.25"
                />
                <stop
                    offset="0.645833"
                    :stop-color="color"
                    stop-opacity="0.19"
                />
                <stop
                    offset="0.723958"
                    :stop-color="color"
                    stop-opacity="0.93"
                />
                <stop
                    offset="0.854167"
                    :stop-color="color"
                    stop-opacity="0.33"
                />
                <stop
                    offset="0.953125"
                    :stop-color="color"
                    stop-opacity="0.623529"
                />
                <stop offset="1" :stop-color="color" />
            </radialGradient>
            <linearGradient
                :id="'paint3_linear_598_7773' + randomId"
                x1="25.5"
                y1="8"
                x2="25.5"
                y2="24.5854"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="color" />
                <stop offset="1" :stop-color="color" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                :id="'paint4_linear_598_7773' + randomId"
                x1="25.5"
                y1="47.47"
                x2="25.5"
                y2="37.636"
                gradientUnits="userSpaceOnUse"
            >
                <stop :stop-color="color" />
                <stop offset="1" :stop-color="color" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
</template>
